
import './App.css';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

function App() {
  return (
    <div style={{marginTop:'2vh'}}>
       <div className="header"> 
        <Header  />
        </div>
        <div className="body">
          <Body />
        </div>
    </div>
  );
}

export default App;
