import React from 'react';
import './App.css';
import Button from '@mui/material/Button';
import net_logo from './images/net.png';
import rn_logo from './images/rnative.png';
import go_logo from './images/golang.png';
import api_logo from './images/apisweb.png';
import gov_logo from './images/gov_logo.png';
import med_logo from './images/med_logo.png';

const Body = () => {
    return(
        <>
            <div className='body_section_intro' style={{backgroundColor: 'white'}}>
                {/* add font types */}
                <div className='section_one_div'>
                    <div className='body_content' style={{minWidth:'50%'}}>
                        <p className='subtitle' style={{color:'#2A4663', clear:'left'}}>
                        Better pricing<text style={{color:'#0165E1'}}>.</text> Better software<text style={{color:'#0165E1'}}>.</text>
                        </p>
                        <p className='body_text' style={{color:'gray'}}>
                            Priding ourselves on delivering the absolute highest standards in code and practice, we offer a unique software experience bringing your visions into reality. Here, better software at better pricing is something we're really all about.
                        </p>
                    </div>
                </div>
            </div>
                <div id='technology' className='section_services'>
                    <div className='body_section_services_left'>
                        <p className='services_title' style={{color:'black'}}> Services & <text style={{color:'#0165E1'}}> Technology </text> </p>
                        <p className='services_blurb' style={{color:'black'}}> Our main competency at it's core involves the creation, design, and implementation of APIs and their supporting backend infrastructures. Database management, telemetry and monitoring, and supporting services and maintenance. While supporting a wide range of programming technologies, full-stack development for both mobile and web applications, and a constant thirst for knowledge gain, our team is sure to stay ahead of the curve with industry best practices.
                        </p>
                    </div>
                    <div className='body_section_services_right'>
                        <table className='tech_table'>
                            <tr>
                                <td>
                                    <div className='tech_div'>
                                    <img style={{width:50}} src={net_logo} />
                                    <p className='tech_subtitle'> .NET framework </p>
                                    <p className="tech_blurb" style={{color:'gray'}}> This is one of our main technologies utilized by our .NET team for building and supporting many of our clients microservice architectures as well as
                                    some of their API integrations. The ease it provides for quick MVP level turn-arounds is amazing and is something our team knows in and out. It's such a strong and powerful language and is definitley something we see as an in-house staple.
                                    </p>
                                    </div>
                                </td>

                                <td>
                                    <div className='tech_div'>
                                    <img src={rn_logo} />
                                    <p className='tech_subtitle'> React Native </p>
                                    <p className="tech_blurb" style={{color:'gray'}}> When it comes to mobile development at any scale, React Native's extensive libraries allow us to build
                                    the very best experiences for any mobile product. Multi-language support for both IOS and Android, coupled with it's active developer community and stable improvements, this technology has been one of our tride and true methods for mobile application. Want to make
                                    an impact? Lets go build something.
                                    </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='tech_div'>
                                        <img style={{width:50, backgroundColor:'#0165E1', borderRadius:10, padding:1}} src={go_logo} />
                                        <p className='tech_subtitle'> Go </p>
                                        <p className="tech_blurb" style={{color:'gray'}}> The "go to" for our team when we need a super powerful tool for building and supporting many of our partners enterprise-level applications. Here we simply love Go for what it is, concurrency out of the box, super scalable and secure, while being conveniently easy for rapid development. It's just perfect for us and our clients.
                                        It even supports some of our own ETL processes. There's literally no limit how far we can "go" with go :) okay we promise that's the last one.
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className='tech_div'>
                                        <img style={{width:50}} src={api_logo} />
                                        <p className='tech_subtitle'> API Development </p>
                                        <p className="tech_blurb" style={{color:'gray'}}> With this as our core competency our team knows how to drive results consistently and efficiently. Combining our expert know-how and a tailored tech stack to fit your solution, this is something we strive in.
                                        And we love it.
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div className='table_mob_view'>
                            <div className='tech_div'>
                                <img className='tech_imgs' style={{width:50}} src={net_logo} />
                                <p className='tech_subtitle'> .NET framework </p>
                                <p className="tech_blurb" style={{color:'gray'}}> This is one of our main technologies utilized by our .NET team for building and supporting many of our clients microservice architectures as well as
                                    some of their API integrations. The ease it provides for quick MVP level turn-arounds is amazing and is something our team knows in and out. It's such a strong and powerful language and is definitley something we see as an in-house staple.
                                </p>
                            </div>

                            <div className='tech_div'>
                                <img className='tech_imgs' src={rn_logo} />
                                <p className='tech_subtitle'> React Native </p>
                                <p className="tech_blurb" style={{color:'gray'}}> When it comes to mobile development at any scale, React Native's extensive libraries allow us to build
                                    the very best experiences for any mobile product. Multi-language support for both IOS and Android, coupled with it's active developer community and stable improvements, this technology has been one of our tride and true methods for mobile application. Want to make
                                    an impact? Lets go build something.
                                </p>
                            </div>

                            <div className='tech_div'>
                                <img className='tech_imgs'  style={{width:50, backgroundColor:'#0165E1', borderRadius:10, padding:1}} src={go_logo} />
                                <p className='tech_subtitle'> Go </p>
                                <p className="tech_blurb" style={{color:'gray'}}> The "go to" for our team when we need a super powerful tool for building and supporting many of our partners enterprise-level applications. Here we simply love Go for what it is, concurrency out of the box, super scalable and secure, while being conveniently easy for rapid development. It's just perfect for us and our clients.
                                    It even supports some of our own ETL processes. There's literally no limit how far we can "go" with go :) okay we promise that's the last one.
                                </p>
                            </div>

                            <div className='tech_div'>
                                <img className='tech_imgs' style={{width:50}} src={api_logo} />
                                <p className='tech_subtitle'> API Development </p>
                                <p className="tech_blurb" style={{color:'gray'}}> With this as our core competency our team knows how to drive results consistently and efficiently. Combining our expert know-how and a tailored tech stack to fit your solution, this is something we strive in.
                                    And we love it.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            <div id='industry' className='section_work'>
                <div className='body_section_services_left'>
                    <p className='services_title' style={{color:'black'}}> Experience <text style={{color:'#0165E1'}}> in Industry </text> </p>
                    <p className='services_blurb' style={{color:'black'}}> Our engineers come from a wide range of backgrounds and experiences and support some of our nations most critical industries. No task is too big or too small for us, seriously.
                    </p>
                </div>
                <div className='body_section_services_right'>
                    <table className='work_table'>
                        <tr>
                            <td>
                                <div className='work_div'>
                                <img style={{width:50}} src={gov_logo} />
                                <p className='tech_subtitle'> Government </p>
                                <p className="tech_blurb" style={{color:'gray'}}>
                                    Understanding the development time and energy it takes to complete and deliver great products and services, we know this industry down to it's core.
                                    We're well versed in helping and sustaining impact working with governments for the technological outcomes that would normally be too costly and timely. By streamlining the development lifecycles and improving processes we aim to improve government efficiency.
                                </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='work_div'>
                                    {/* text className='tech_title'> .NET </text> */}
                                    <img style={{width:55}} src={med_logo} />
                                    <p className='tech_subtitle'> Healthcare & IT </p>
                                    <p className="tech_blurb" style={{color:'gray'}}> Data integrity and security is built into the fabric of everything we do here which has allowed us to provide
                                    some of the very best health care technologies to our partners. The cornerstone of this industry depends on reliability and secure practices which is something our team has under constant technical
                                    review and scrutiny. This combined with our years of experience allow us to provide the very best software and services to improve trust between patients and their health care providers.
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div className='table_mob_view'>
                        <div className='work_div'>
                            {/* text className='tech_title'> .NET </text> */}
                            <img className='work_imgs' style={{width:50}} src={gov_logo} />
                            <p className='tech_subtitle'> Government </p>
                            <p className="tech_blurb" style={{color:'gray'}}>
                                Understanding the development time and energy it takes to complete and deliver great products and services, we know this industry down to it's core.
                                We're well versed in helping and sustaining impact working with governments for the technological outcomes that would normally be too costly and timely. By streamlining the development lifecycles and improving processes we aim to improve government efficiency.
                            </p>
                        </div>

                        <div className='work_div'>
                            {/* text className='tech_title'> .NET </text> */}
                            <img className='work_imgs' style={{width:55}} src={med_logo} />
                            <p className='tech_subtitle'> Healthcare & IT </p>
                            <p className="tech_blurb" style={{color:'gray'}}> Data integrity and security is built into the fabric of everything we do here which has allowed us to provide
                                some of the very best health care technologies to our partners. The cornerstone of this industry depends on reliability and secure practices which is something our team has under constant technical
                                review and scrutiny. This combined with our years of experience allow us to provide the very best software and services to improve trust between patients and their health care providers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id='partnership' className='section_footer'>
            <div className='body_section_services_left'>
                <p className='footer_title' style={{color:'white'}}> Lets Build<text style={{color:'#0165E1'}}>. </text> </p>

                <div className='footer_blurb'>
                    <text style={{color:'white'}}> Come explore the limitless possibilities of building great products with great people.
                    </text>
                    <br />
                    <a style={{textDecoration:"none"}} href="mailto:development@cloudtrees.io">
                        <text style={{color:'#0165E1',fontWeight:'bold'}}>
                            development@cloudtrees.io
                        </text>
                    </a>
                    <br />
                    <text style={{color:'white'}}>
                        &copy;2024 Cloudtree, LLC. All rights reserved.
                    </text>
                </div>
            </div>
                <div className='body_section_services_right'>
                    <table className='footer_table'>
                        <tr>
                            <td>
                                <div className='footer_div'>
                                    {/* text className='tech_title'> .NET </text> */}
                                    <p className='footer_title_text'> Company </p>
                                    <p className="footer_list_items" style={{color:'white'}}>
                                        <a style={{textDecoration:"none"}} href='#technology'> <text className='footer_text'> Work </text> </a>
                                        <br />
                                        <a style={{textDecoration:"none"}} href="mailto:development@cloudtrees.io"> <text className='footer_text'> Contact </text> </a>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='footer_div'>
                                    <p className='footer_title_text'>  Services </p>
                                    <p className="footer_list_items" style={{color:'white'}}>
                                        <text className="footer_text"> API Development </text>
                                        <br />
                                        <text className="footer_text"> Software Backend Support </text>
                                        <br />
                                        <text className="footer_text"> Custom Application Design </text>
                                        <br />
                                        <text className="footer_text"> Software Consulting </text>
                                        <br />
                                        <text className="footer_text"> MVP Prototyping </text>
                                        <br />
                                        <text className="footer_text"> Mobile App Development </text>
                                        <br />
                                        <text className="footer_text"> Integration Support </text>
                                        <br />
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </>
    );
}
export default Body;