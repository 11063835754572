import React from 'react';
import {createUseStyles} from 'react-jss';
import yt_logo from './images/yt_logo.png';
import link_logo from './images/linkedin_logo.png';

const Footer = () => {
    const styles = useStyles();
    return(
        <>
            <div className='footer_content'>
                <p style={{textAlign:'center', marginLeft:'1vw'}}>

                    {/* todo later change out */}
                    <a href="https://www.youtube.com/@cloudtree-ju5yz">
                        <img className='footer_images' alt="linkedin cloudtrees" style={{marginRight:15, width:19, height:19,verticalAlign:'middle'}} src={link_logo} />
                    </a>

                    <a href="https://www.youtube.com/@cloudtree-ju5yz">
                        <img style={{marginRight:15, verticalAlign:'middle'}} src={yt_logo} alt='youtube cloudtrees' />
                    </a>
                </p>
                <p style={{color:'white'}}>
                    &copy;2024 Cloudtree, LLC. All rights reserved.
                </p>
            </div>
        </>
    );
}

const useStyles = createUseStyles({
})

export default Footer;