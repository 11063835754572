import React from 'react';
import {createUseStyles} from 'react-jss';

const Header = () => {
    const styles = useStyles();
    return(
        <>
            <div className='headerParent'>
                <div className='divHeader'>
                    <div>
                        <text  className='title' style={{color:'#0165E1'}}> cloudtree </text>
                    </div>
                    <div className='contact_button_div' style={{paddingTop:10}}>
                       <a className='contact_button' style={{fontSize:14, textDecoration:"none"}} href="mailto:development@cloudtrees.io"> Contact Us</a>
                    </div>
                </div>
                <div className='header_links_div' style={{textAlign:'center'}}>
                    <div className='hover-underline-animation'>
                        <a className='header_links' href='#technology' style={{paddingInline:30}}> What we do </a>
                    </div>
                    <div className='hover-underline-animation'>
                        <a className='header_links' href='#industry' style={{paddingInline:30}}> Work </a>
                    </div>
                    <div className='hover-underline-animation'>
                        <a className='header_links' href='#partnership' style={{paddingInline:30}}> Inquries </a>
                    </div>
                </div>
            </div>
        </>
    );
}

const useStyles = createUseStyles({
    button: {
        border: 'none',
        padding: 15,
        //paddingLeft: 35,   <img src={logo} alt="logo" width={250} /> 21951b
        //paddingRight: 35,
        fontSize: 22,
        borderRadius: 50,
        backgroundColor: '#ff8351',
        borderWidth: .75,
        borderColor: '#ff8351',
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: '#ff8351'
        }
    }
})

export default Header;